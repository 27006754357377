<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="uil uil-credit-card display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">RRP Setting and List of Payment Gateway</small>
                </div>
              </div>
             
              <div class="ms-auto" v-if="account_type=='admin' && addPgInfo.length>0 && create_modify_permission">
                <button class="btn btn-outline-info fw-medium" @click="addPg()">
                <i class="uil uil-plus font-size-16 me-1"></i> Add Payment Gateway
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center mb-2">
              <div class="col-12">
                <div class="d-md-flex">
                  <div class="d-flex col-12 col-md-4 me-md-2 mb-3">
                    <div class="input-group">
                      <div class="input-group-text py-0 custom_top_data_bar text-white border-dark fw-medium"><i class="uil uil-usd-circle me-1 text-primary"></i> Currency</div>
                            <multiselect v-model="selectedPaymentGatewayCurrency" :options="paymentGatewayCurrency" label="name" track-by="name" class="form-control m-0 p-1" placeholder="Select a Currency" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getData(), availableToAddPg()">
                            <template slot="singleLabel" slot-scope="props">
                              <span v-if="props.option.value!==''">
                                <span class="option__title">{{ props.option.name }} ({{ props.option.value }})</span>
                              </span>
                            </template>
                            <template slot="option" slot-scope="props">
                              <span v-if="props.option.name!==''" class="option__title">{{ props.option.name }}</span>
                            </template>
                            
                            <span slot="noResult">Oops! No currency found. </span>
                          </multiselect>
                        </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-auto d-none">
                <div class="row">
                  <div class="col-6 pe-1 d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      @click="clear()"
                      >
                    <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="col-6 ps-1 d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      @click="search()"
                      >
                    <i class="uil-search me-1"></i> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div>
                <h4 class="fw-medium font-size-18 text-primary mb-3"> RRP Setting ({{ selectedPaymentGatewayCurrency.value ||'-' }})</h4>
              </div>
              <div class="card shadow-none border">
                <div class="custom_top_data_bar rounded-top text-uppercase font-size-12 p-3 py-2">
                  <div class="fw-semibold">General Setting</div>
                </div>
                <div class="p-3">
                <div class="row" v-if="loading">
                  <div class="col">
                    <div class="py-3 text-center text-muted">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                        >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </div>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-lg-4">
                    <div class="mb-1">
                      <div class="input-group">
                        <span class="input-group-text">Min. Deposit</span>
                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit4 && $v.formEditData2.min_deposit.$error}" 
                          v-model="formEditData2.min_deposit" placeholder="Min. Deposit" @input="inputNumberOnlyV2($event, 'formEditData2','min_deposit')" :disabled="loading">
                        <div
                          v-if="modalSubmit4 && $v.formEditData2.min_deposit.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData2.min_deposit.required"
                            >Min. Deposit is required. </span
                            >
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 mb-lg-0">
                      <div class="input-group">
                        <span class="input-group-text">Max. Deposit</span>
                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit4 && $v.formEditData2.max_deposit.$error}" 
                          v-model="formEditData2.max_deposit" placeholder="Max. Deposit" @input="inputNumberOnlyV2($event, 'formEditData2','max_deposit')" :disabled="loading">
                          <div
                          v-if="modalSubmit4 && $v.formEditData2.max_deposit.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData2.max_deposit.required"
                            >Max. Deposit is required. </span
                            >
                        </div>
                      </div>
                     
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-1">
                      <div class="input-group">
                        <span class="input-group-text">Min. Payout</span>
                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit4 && $v.formEditData2.min_payout.$error}" 
                          v-model="formEditData2.min_payout" placeholder="Min. Payout" @input="inputNumberOnlyV2($event, 'formEditData2','min_payout')" :disabled="loading">
                        <div
                          v-if="modalSubmit4 && $v.formEditData2.min_payout.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData2.min_payout.required"
                            >Min. Payout is required. </span
                            >
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 mb-lg-0">
                      <div class="input-group">
                        <span class="input-group-text">Max. Payout</span>
                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit4 && $v.formEditData2.max_payout.$error}" 
                          v-model="formEditData2.max_payout" placeholder="Max. Payout" @input="inputNumberOnlyV2($event, 'formEditData2','max_payout')" :disabled="loading">
                        <div
                          v-if="modalSubmit4 && $v.formEditData2.max_payout.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData2.max_payout.required"
                            >Max. Payout is required. </span
                            >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-1">
                      <div class="input-group">
                        <span class="input-group-text">Min. Settlement</span>
                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit4 && $v.formEditData2.min_settlement.$error}" 
                          v-model="formEditData2.min_settlement" placeholder="Min. Settlement" @input="inputNumberOnlyV2($event, 'formEditData2','min_settlement')" :disabled="loading">
                        <div
                          v-if="modalSubmit4 && $v.formEditData2.min_settlement.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData2.min_settlement.required"
                            >Min. Settlement is required. </span
                            >
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 mb-lg-0">
                      <div class="input-group">
                        <span class="input-group-text">Max. Settlement</span>
                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit4 && $v.formEditData2.max_settlement.$error}" 
                          v-model="formEditData2.max_settlement" placeholder="Max. Settlement" @input="inputNumberOnlyV2($event, 'formEditData2','max_settlement')" :disabled="loading">
                        <div
                          v-if="modalSubmit4 && $v.formEditData2.max_settlement.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData2.max_settlement.required"
                            >Max. Settlement is required. </span
                            >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div v-if="!loading" class="align-middle text-end bg-light rounded-bottom px-3 py-2">
                    <div v-if="create_modify_permission">
                      <button type="button" class="btn fw-medium btn-info" @click="updateCompanySetting" :disabled="modalLoading4 || loading">
                      <span v-if="modalLoading4"> Updating...</span>
                      <span v-else> Update Setting </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading4" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                    <div v-else>
                      <button type="button" class="btn fw-medium btn-info" disabled="true">
                        Update Setting
                      </button>
                    </div>
                </div> 
              </div>
              <div class="card shadow-none border border-top-0 mb-5">
                <div class="row d-none">
                  <div class="col-lg-4">
                    <div class="mb-3 row">
                      <label class="col-lg-auto col-form-label">Min. Deposit</label>
                      <div class="col-lg">

                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.min_deposit.$error}" 
                          v-model="formEditData.min_deposit" placeholder="Min. Deposit" @input="inputNumberOnlyV2($event, 'formEditData','min_deposit')" :disabled="loading">
                        <div
                          v-if="modalSubmit && $v.formEditData.min_deposit.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData.min_deposit.required"
                            >Min. Deposit is required. </span
                            >
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3 row">
                      <label class="col-lg-auto col-form-label">Max. Deposit</label>
                      <div class="col-lg">
                        <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.max_deposit.$error}" 
                          v-model="formEditData.max_deposit" placeholder="Max. Deposit" @input="inputNumberOnlyV2($event, 'formEditData','max_deposit')" :disabled="loading">
                        <div
                          v-if="modalSubmit && $v.formEditData.max_deposit.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditData.max_deposit.required"
                            >Max. Deposit is required. </span
                            >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-responsive text-nowrap font-size-14 rounded-top mb-0">
                    <table class="table align-middle rounded-top mb-0" >
                      <thead class="custom_top_data_bar text-uppercase rounded-top  font-size-12">
                        <tr>
                          <th>{{defaultName}}</th>
                          <th>Payin Rate and Extra Charges</th>
                          <th>Payout Rate and Extra Charges</th>
                          <th>Settlement Rate and Extra Charges</th>
                          <th>E-Wallet Rate and  Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="loading">
                          <td colspan="5" class="text-center text-muted" height="120">
                            <div
                              class="spinner-border text-secondary my-2"
                              role="status"
                              >
                              <span class="sr-only">Loading...</span>
                            </div>
                            <br />
                            <div>Loading...</div>
                          </td>
                        </tr>
                        <tr v-if="!loading">
                          <td class="border-end">Cost Setting
                            <input type="text" class="form-control" v-model="formEditData.currency" hidden>
                          </td>
                          <td class="align-top border-end">
                            <div class="row">
                              <div class="col-12 col-lg-6 mb-1 mb-lg-0">
                                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_rate.$error}" 
                                  v-model="formEditData.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payin_rate')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!$v.formEditData.payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!$v.formEditData.payin_rate.between"
                                    >The payin rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-12 col-lg-6">
                                <input type="text" inputmode="decimal" class="form-control text-start" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_extra_charge.$error}" 
                                  v-model="formEditData.payin_extra_charge" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV2($event, 'formEditData','payin_extra_charge')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!$v.formEditData.payin_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!$v.formEditData.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00 </span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top border-end">
                            <div class="row">
                              <div class="col-12 col-lg-6 mb-1 mb-lg-0">
                                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_rate.$error}" 
                                  v-model="formEditData.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payout_rate')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!$v.formEditData.payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!$v.formEditData.payout_rate.between"
                                    >The payout rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-12 col-lg-6">
                                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_extra_charge.$error}" 
                                  v-model="formEditData.payout_extra_charge" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV2($event, 'formEditData','payout_extra_charge')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!$v.formEditData.payout_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!$v.formEditData.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00 </span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top border-end">
                            <div class="row">
                              <div class="col-12 col-lg-6 mb-1 mb-lg-0">
                                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_rate.$error}" 
                                  v-model="formEditData.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','settlement_rate')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!$v.formEditData.settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!$v.formEditData.settlement_rate.between"
                                    >The settlement rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-12 col-lg-6">
                                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_extra_charge.$error}" 
                                  v-model="formEditData.settlement_extra_charge" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV2($event, 'formEditData','settlement_extra_charge')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!$v.formEditData.settlement_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!$v.formEditData.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00 </span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top">
                            <div class="row">
                              <div class="col-12 col-lg-6 mb-1 mb-lg-0">
                                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_rate.$error}" 
                                  v-model="formEditData.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_rate')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!$v.formEditData.ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!$v.formEditData.ewallet_rate.between"
                                    >The e-wallet rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-12 col-lg-6">
                                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_extra_charge.$error}" 
                                  v-model="formEditData.ewallet_extra_charge" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_extra_charge')" >
                                <div
                                  v-if="modalSubmit && $v.formEditData.ewallet_extra_charge.$error"
                                  class="invalid-feedback text-wrap"
                                  >
                                  <span v-if="!$v.formEditData.ewallet_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!$v.formEditData.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00 </span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <pre>{{ estData }}</pre> -->
                </div>
                <div v-if="!loading" class="align-middle text-end bg-light rounded-bottom px-3 py-2">
                    <div v-if="create_modify_permission">
                      <button type="button" class="btn fw-medium btn-info" @click="updateCompanyCost" :disabled="modalLoading || loading">
                      <span v-if="modalLoading"> Updating...</span>
                      <span v-else> Update Cost </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                    <div v-else>
                      <button type="button" class="btn fw-medium btn-info" disabled="true">
                        Update Cost
                      </button>
                    </div>
                </div> 
              </div>
                <div class="col-12 mt-3">
                  <div class="d-md-flex align-items-center ">
                    <div class="d-flex me-auto">
                      <div>
                      <h4 class="fw-medium font-size-18 text-primary mb-2 mb-md-1"> List of Payment Gateway ({{ selectedPaymentGatewayCurrency.value ||'-' }})</h4>
                      <div class="form-check form-switch form-check-custom mb-2">
                        <label for="autoNextPg" class="fw-normal">Auto Next PG By Sequence</label>
                        <input id="autoNextPg" class="form-check-input" type="checkbox" role="switch" v-model="autoNextPg" true-value="true" false-value="false" :disabled="loading || modalLoading3" @change="updateAutoNextPg(selectedPaymentGatewayCurrency.value)">
                      </div>
                       </div>
                    </div>
                    <div class="d-flex mb-1 ms-auto">
                      <div class="app-search2 position-relative">
                        <input
                          type="search"
                          placeholder="Filter..."
                          class="form-control mb-2"
                          v-model.trim="searchKey"
                          autocomplete="no"
                          @keyup="filterByResult()"
                          />
                        <span class="uil-search text-info"></span>
                      </div>
                      <button
                        class="btn btn-light mb-2 ms-2 text-nowrap"
                        type="button"
                        @click="clear()"
                        >
                      <i class="uil-redo me-1"></i> Reset
                      </button>
                    </div>
                  </div>
                </div>
                
              
              <div class="table-responsive text-nowrap font-size-14">
                <table class="table mb-3 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                  <thead class="custom_top_data_bar text-uppercase align-middle font-size-12">
                    <tr>
                      <th>Name<br>Code</th>
                      <th>Total Deposit/Limit</th>
                      <th>Total Balance</th>
                      <th>Min. Deposit<br>Max. Deposit</th>
                      <th class="d-none">Country<br>Currency</th>
                      <th>Payin Rate<br>and Extra Charge</th>
                      <th>Payout Rate <br>and Extra Charge</th>
                      <th>Settlement Rate<br>and Extra Charge</th>
                      <th>E-Wallet Rate<br>and Extra Charge</th>
                      <th>Category</th>
                      <th>PG Extra Info</th>
                      <th class="text-center">Status</th>
                      <th class="text-center" v-if="create_modify_permission">Sequence</th>
                      <th class="text-center" v-if="create_modify_permission">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!filterByResult().length && !loading && searchKey">
                      <td :colspan="create_modify_permission ? 13 : 11"  height="200" class="text-center text-muted">
                        <i class="uil uil-credit-card me-1"></i>  Sorry, we couldn't
                        find any results of "{{ searchKey }}".
                      </td>
                    </tr>
                    <tr v-if="loading">
                      <td :colspan="create_modify_permission ? 13 : 11" class="text-center text-muted" height="300">
                        <div
                          class="spinner-border text-secondary my-2"
                          role="status"
                          >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <br />
                        <div>Loading...</div>
                      </td>
                    </tr>
                    <tr v-if="!returnData.length && !loading">
                      <td :colspan="create_modify_permission ? 13 : 11" height="200" class="text-center text-muted">
                        <i class="uil uil-credit-card me-1"></i> No Data Available
                      </td>
                    </tr>
                    <tr v-for="(value, index) in filterByResult()" :key="index" :class="{'bg-light opacity-50':value.status==-1, 'bg-inactive': value.status==0}">
                      <td>
                        <strong class="fw-medium text-info">{{ value.payment_gateway_name }}</strong><br>
                        {{ value.payment_gateway_code }}
                      </td>
                      <td>
                       
                        <span v-if="value.wallet_info.deposit_balance>value.balance_limit" class="text-danger"> {{ value.currency }} {{ convertCurrencyFormat(value.wallet_info.deposit_balance,true) }}</span>
                        <span v-else>{{ value.currency }} {{ convertCurrencyFormat(value.wallet_info.deposit_balance,true) }}</span>
                        /
                        <span v-if="value.balance_limit ">{{ convertCurrencyFormat(value.balance_limit || 0,false) }} </span>
                        <span v-else> 0.00 </span>

                        <span v-if="value.wallet_info.deposit_balance>value.balance_limit" class="text-danger"><i class="mdi mdi-alert-circle"></i></span>
                      </td>
                      <td>
                        {{ value.currency }} <span v-if="value.wallet_info.deposit_balance">
                          {{ convertCurrencyFormat(value.wallet_info.deposit_balance,true) }}
                        </span><span v-else>
                         0.00
                        </span>  (System)<br>
                        <!-- {{ value.currency }} {{ convertCurrencyFormat(value.wallet_info.deposit_balance,true) }} (PG) -->
                        <a href="javascript:void(0)" class="text-primary d-block rounded" @click="editPgBalance(value)" v-if="create_modify_permission">
                          {{ value.currency }} {{ convertCurrencyFormat(value.wallet_info.deposit_balance,true) }} (PG)
                        <i class="uil uil-edit ms-1"></i>
                        </a>
                      </td>
                      <td>
                        {{ value.currency }} 
                        <span v-if="value.min_deposit">
                          {{ convertCurrencyFormat(value.min_deposit,true)  }}
                        </span><br>

                        {{ value.currency }} 
                        <span v-if="value.max_deposit">
                          {{ convertCurrencyFormat(value.max_deposit,true) }}
                        </span>
                      </td>
                      <!-- <td>
                        {{ value.country }}<br>{{ value.currency }}
                      </td> -->
                      <td>
                        <div v-if="value.supported_type.includes('payin')">
                          <div> {{ value.payin_rate }}% + {{ convertCurrencyFormat(value.payin_extra_charge,true)  }}</div>
                          <span class="fw-medium font-size-13 text-success text-capitalize"><i class="uil uil-check-circle"></i> Support</span>
                        </div>
                        <div v-else>N/A</div>
                      </td>
                      <td>
                        <div v-if="value.supported_type.includes('payout') ">
                            {{ value.payout_rate }}% + {{ convertCurrencyFormat(value.payout_extra_charge,true)  }}
                          <div v-if="value.supported_type.includes('auto_payout')">
                            <span class="fw-medium font-size-13 text-success text-capitalize"><i class="uil uil-check-circle"></i> Auto Payout</span>
                          </div>
                          <div v-if="value.supported_type.includes('manual_payout')">
                            <span class="fw-medium font-size-13 text-success text-capitalize"><i class="uil uil-check-circle"></i> Manual Payout</span>
                          </div>
                        </div>
                        <div v-else>
                          <div>N/A</div>
                        </div>
                        
                      </td>
                      <td>
                        
                        <div v-if="value.supported_type.includes('settlement')">
                          <div>{{ value.settlement_rate }}% + {{ convertCurrencyFormat(value.settlement_extra_charge,true)  }}</div>
                          <span class="fw-medium font-size-13 text-success text-capitalize"><i class="uil uil-check-circle"></i> Support</span>
                        </div>
                        <div v-else>
                          <div>N/A</div>
                        </div>
                      </td>
                      <td>
                        <div v-if="value.supported_type.includes('ewallet')">
                          <div>{{ value.ewallet_rate }}% + {{ convertCurrencyFormat(value.ewallet_extra_charge,true)  }}</div>
                          <span class="fw-medium font-size-13 text-success text-capitalize"><i class="uil uil-check-circle"></i> Support</span>
                        </div>
                        <div v-else>
                          <div>N/A</div>
                        </div>
                      </td>
                      <td>
                        <div v-if="value.categories.length">
                          <div v-for="(v,i) in value.categories" :key="i"><span class="badge font-size-13 p-1 px-2 me-0 bg-soft-warning text-orange text-capitalize" :class="{'mb-1': (value.categories.length-1) !== i}">{{ v }}</span></div>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-outline-info" @click="viewExtraInfo(value)">Extra Info</button>
                      </td>
                      <td class="text-center" >
                        <!-- <div class="form-check form-switch form-switch-md d-inline-block" v-if="create_modify_permission">
                          <input class="form-check-input" type="checkbox" @change="updateStatus(value)" role="switch" v-model="value.status" true-value="1" false-value="0" :disabled="submitted">
                        </div>
                       
                    
                        <div class="form-check form-switch form-switch-md" v-else>
                          <input class="form-check-input" type="checkbox" role="switch" v-model="value.status" true-value="1" false-value="0" disabled="true">
                        </div> -->
                        <select v-if="create_modify_permission" class="form-select pe-3"  @change="updateStatus(value)" v-model="value.status" :disabled="submitted" style="min-width: 95px;">
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                          <option value="-1">Disabled</option>
                        </select>
                        <select v-else class="form-select" :class="{'border-success': value.status=='1'}" v-model="value.status" :disabled="submitted" style="min-width: 95px;">
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                          <option value="-1">Disabled</option>
                        </select>
                      </td>
                      <td class="text-center" v-if="create_modify_permission">
                        <div class="input-group">
                            <input type="text" class="form-control text-center" placeholder="Sequence" v-model="value.sequence" style="width: 80px;" @input="inputNumberOnlyV3($event, 'returnData','sequence',index)" :disabled="modalLoading2 || loading" required>
                          </div>
                      </td>
                      <td class="text-center" v-if="create_modify_permission">
                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="editPg(value)">
                      <i class="uil uil-edit"></i></button>
                        <!-- <a href="javascript:void(0)" v-b-tooltip.hover title="Remove" class="text-primary font-size-16 fw-medium" @click="removePaymentGateway(value)"><i class="uil uil-trash-alt"></i></a> -->
                      </td>
                    </tr>
                    <tr v-if="!loading && create_modify_permission">
                        <td class="align-middle text-end bg-light" colspan="13">
                          <div class="">
                            <button type="button" class="btn fw-medium btn-info" @click="updateSequence" :disabled="modalLoading2">
                            <span v-if="modalLoading2"> Updating...</span>
                            <span v-else> Update Sequence </span>
                            <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading2" role="status" aria-hidden="true"></span>
                            </button>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <div class="row" v-if="returnData.length">
                <div class="col mt-3 d-none">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                    >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        hide-ellipsis
                        @change="handlePageChange"
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="totalData"
                        >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <b-modal
      @hidden="resetModal"
      ref="modal-extra-info"
      centered
      hide-footer
      size="lg"
      hide-header
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
      >
      <div class="row align-items-center">
        <div class="col-12 text-white" >
          <h5 class="text-info mb-3">{{extraInfo.payment_gateway_name}} - Extra Info</h5>
        </div>
        <div class="col-12 text-muted">
         <pre v-if="extraInfo">{{ JSON.parse(extraInfo.extra_info|| '{}') }}</pre>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-extra-info'].hide()">Cancel</button>
          </div>
        </div>
      </div>
    </b-modal>
    <pgModal ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}"  @callParentFunction="getData()" />
  </Layout>
</template>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Lottie from "@/components/lottieView";
  import Common from "@/components/common";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Multiselect from "vue-multiselect";
  import { required, minLength, sameAs,between, helpers } from "vuelidate/lib/validators";
  import pgModal from "@/views/pages/app/modal/createEditPg";

  const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  const validWebsiteUrl = (value) => {
    console.log(value);
    const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    return regex.test(value);
  }
  const validUsername = (value) => {
    const regex = /^[a-z0-9]+$/;
    return regex.test(value);
  };
  


  /**
   * Starter page
   */
  const PageTitle = "Payment Gateway"
  const paymentGatewayCurrencyDefault = {
    "name":"Select Currency",
    "value":""
  }
  export default {
    components: { 
      Layout, 
      PageHeader, 
      Lottie,
      Common,
      Multiselect,
      pgModal
    },
    page() {
      return {
        title: PageTitle,
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      }
    },
    data() {
      return {
        defaultName: appConfig.defaultAccountName,
        search_value:'',
        searchKey:'',
        searchKey2:'',
        accessToken:'',
        accessEmail:'',
        accessPhone:'',
        accessUsername:'',
        account_type:"",
        showLottie:false,
        updateData:{},
        title: '',
        items: [
          {
            text: appConfig.pageTitle,
            to: "/",
          },
          {
            text: '',
            active: true,
          },
        ],
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        currentPage:1,
        perPage: 20,
        totalData: 0,
        submitted:false,
        modalSubmit:false,
        modalLoading:false,
        modalLoading2:false,
        modalLoading3:false,
        modalSubmit4:false,
        modalLoading4:false,
        loading:true,
        loading2:true,
        buttonLoading:[false,false,false],
        returnData: [],
        selectedPaymentGatewayCurrency: paymentGatewayCurrencyDefault,
        paymentGatewayCurrency:[],
        autoNextPg: false,
        extraInfo:{},
        formData: {
          username:"",
          name:"",
          apiKey: "",
          paymentUrl: "",
          returnUrl: "",
          websiteUrl: "",
          password: "",
          confirmPassword: "",
          otp: "",
        },
        formEditData: {
          id:"",
          min_deposit:"",
          max_deposit:"",
          currency:"",
          payin_rate: "",
          payin_extra_charge: "0",
          payout_rate: "",
          payout_extra_charge: "0",
          settlement_rate: "",
          settlement_extra_charge: "0",
          ewallet_rate: "",
          ewallet_extra_charge: "0",
        },
        formEditData2: {
          id:"",
          currency:"",
          min_deposit:"",
          max_deposit:"",
          min_payout:"",
          max_payout:"",
          min_settlement:"",
          max_settlement:"",
        },
        addPgInfo:[],
        create_modify_permission:false
      };
    },
    validations: {
      formData: {
        username: {
          required,
          validUsername
        },
        name: {
          required,
        },
        apiKey: {
          required,
        },
        paymentUrl: {
          required,
          validWebsiteUrl
        },
        returnUrl: {
          required,
          validWebsiteUrl
        },
        websiteUrl: {
          required,
          validWebsiteUrl
        },
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        }
      },
      formEditData: {
        currency: {
          required,
        },
        min_deposit: {
          required,
        },
        max_deposit: {
          required,
        },
        payin_rate: {
          required,
          between: between(0,50)
        },
        payin_extra_charge: {
          required,
          between: between(0,100000)
        },
        payout_rate: {
          required,
          between: between(0,50)
        },
        payout_extra_charge: {
          required,
          between: between(0,100000)
        },
        settlement_rate: {
          required,
          between: between(0,50)
        },
        settlement_extra_charge: {
          required,
          between: between(0,100000)
        },
        ewallet_rate: {
          required,
          between: between(0,50)
        },
        ewallet_extra_charge: {
          required,
          between: between(0,100000)
        }
      },
      formEditData2: {
        currency: {
          required,
        },
        min_deposit: {
          required,
        },
        max_deposit: {
          required,
        },
        min_payout: {
          required,
        },
        max_payout: {
          required,
        },
        min_settlement: {
          required,
        },
        max_settlement: {
          required,
        }
      }
    },
    middleware: "authentication",
    async mounted(){
     
      this.title = PageTitle
      this.items[1].text = PageTitle
      // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      // this.$refs.main.changeVerticalTopBar("bill",true)
      // this.$refs.main.setShowFooterCert(false)
      // this.$refs.main.setPageTitle('title')
      await this.reload()
      this.accessToken = this.$refs.commonFunc.getToken()
      this.accessUsername = this.$refs.commonFunc.getUsername()
      // this.getData();
      // this.getCosting();
      this.getPaymentGatewayCurrency();
    }, 
    created(){
     
    },
    methods:{
      updateAutoNextPg(currency){
        console.log(currency)
        this.$Progress.start();
        this.modalLoading3 = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("currency", currency);
        bodyFormData.append("jumpStatus", this.autoNextPg);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/updateAutoNextPgBySequence',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.getData()
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  this.autoNextPg = !this.autoNextPg
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops.1..',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading3 = false;
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.autoNextPg = !this.autoNextPg
              this.modalLoading3 = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        // if(numericValue.length > 2){
        //     var val = numericValue.replace(/[^0-9]/g, '');
        //     console.log(val);
        //     val = val.substr(0, val.length-2)+"."+val.substr(-2);
        //     this[parentModel][childModel] = val;
        // }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9]/g, '');
        this[parentModel][index][childModel] = numericValue
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        //this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
      },
      convertCurrencyFormat(value,show00) {
        if (this.$refs.commonFunc){
          if (show00 == true) {
            return this.$refs.commonFunc.convertCurrencyFormat(value);
          } else {
            // Assuming this.transactionLimit is defined in your component's data
            return this.$refs.commonFunc
              .convertCurrencyFormat(value)
              .replaceAll(".00", "");
          }
        }
      },
      inputNumberOnly(event) {
        var numericValue = event.target.value.replace(/[^0-9]/g, '');
        this.addBank.bank_account_no = numericValue;
        if (event.target.value !== numericValue) {
            return
        }
      },
      inputAmount(event) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this.addUPI.transaction_limit = numericValue;
        this.addBank.transaction_limit = numericValue;
        if (event.target.value !== numericValue) {
            return
        }
      },
      updateAmount() {
        const numericValue = this.addUPI.transaction_limit.replace(/[^0-9.]/g, '');
        if (numericValue !== '') {
            const amountNumber = parseInt(numericValue);
            if (!isNaN(amountNumber)) {
                this.addUPI.transaction_limit = amountNumber.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true
                });
                this.addBank.transaction_limit = amountNumber.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true
                });
            } else {
              this.addUPI.transaction_limit = '10,000';
              this.addBank.transaction_limit = '10,000';
            }
        } else {
          this.addUPI.transaction_limit = '10,000';
          this.addBank.transaction_limit = '10,000';
        }
      },
      viewExtraInfo(data) {
       this.$refs['modal-extra-info'].show()
       this.extraInfo = data;
      },
      editPg(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc.showEditModal(),100)
      },
      addPg(){
        this.updateData= {'data':this.addPgInfo, 'currency':this.selectedPaymentGatewayCurrency.value};
        setTimeout(() =>  this.$refs.modalFunc.showAddModal(),100)
      },
      editPgBalance(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc.showEditPgBalanceModal(),100)
      },
      resetModal() {
        this.extraInfo = {};
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData={
          username:"",
          name:"",
          paymentUrl: "",
          returnUrl: "",
          websiteUrl: "",
          password: "",
          confirmPassword: "",
        }
      },
      async getPaymentGatewayCurrency(){
          this.$Progress.start();
          this.loading = true
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/getSupportedCurrency',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
              
                this.selectedPaymentGatewayCurrency = [paymentGatewayCurrencyDefault]
                this.paymentGatewayCurrency = []
                // var data = [
                //   {
                //     "name":"Malaysia (MYR)",
                //     "value":"MYR"
                //   },
                //   {
                //     "name":"India (INR)",
                //     "value":"INR"
                //   },
                //   {
                //     "name":"Indonesia (IDR)",
                //     "value":"IDR"
                //   },
                //   {
                //     "name":"Vietnam (VND)",
                //     "value":"VND"
                //   }
                // ]
                resData.data.forEach(element => {
                  this.paymentGatewayCurrency.push(element)
                });
                if (resData.data.length > 0){
                  this.selectedPaymentGatewayCurrency =  this.paymentGatewayCurrency[0]
                }
                this.getData()
                this.availableToAddPg();
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
                this.loading = false;
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  this.loading = false;
              }
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      async availableToAddPg(){
          this.$Progress.start();
          this.loading = true
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("currency",  this.selectedPaymentGatewayCurrency.value);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/getOneToOnePgInfo',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.addPgInfo = resData.data;
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
                this.loading = false;
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  this.loading = false;
              }
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      async getData(){
          this.$Progress.start();
          this.loading = true;
          this.formEditData.min_deposit = ''
          this.formEditData.max_deposit = ''
          this.formEditData2={
            id:"",
            currency:"",
            min_deposit:"",
            max_deposit:"",
            min_payout:"",
            max_payout:"",
            min_settlement:"",
            max_settlement:"",
          },
          
          this.returnData = [];
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("currency",  this.selectedPaymentGatewayCurrency.value);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/getPgListByCurrency',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                var status;
                resData.pg_list.forEach(element => {
                  if (element.status  === 'activated'){
                    status = "1"
                  }else if (element.status ==='disabled'){
                    status = "-1"
                  }else{
                    status = "0"
                  }
                  this.returnData.push({
                    id: element.id,
                    payment_db_code: element.payment_db_code,
                    payment_gateway_name: element.payment_gateway_name,
                    payment_gateway_code: element.payment_gateway_code,
                    extra_info: JSON.stringify(element.extra_info),
                    wallet_info: element.wallet_info,
                    payin_rate: element.rate_info.payin_rate,
                    payin_extra_charge: element.rate_info.payin_extra_charges,
                    payout_rate: element.rate_info.payout_rate,
                    payout_extra_charge: element.rate_info.payout_extra_charges,
                    settlement_rate: element.rate_info.settlement_rate,
                    settlement_extra_charge: element.rate_info.settlement_extra_charges,
                    ewallet_rate: element.rate_info.ewallet_rate,
                    ewallet_extra_charge: element.rate_info.ewallet_extra_charges,
                    currency: element.currency,
                    country: element.country,
                    sequence: element.sequence,
                    supported_type:  element.supported_type,
                    min_deposit: element.rate_info.minumum_deposit,
                    max_deposit: element.rate_info.maximum_deposit,
                    balance_limit:  element.rate_info.balance_limit,
                    categories:  element.categories,
                    status: status,
                  });
                })
                //getCosting
                this.formEditData= {
                  id:"",
                  currency: this.selectedPaymentGatewayCurrency.value,
                  min_deposit: resData.own_cost_info.minumum_deposit,
                  max_deposit:resData.own_cost_info.maximum_deposit,
                  payin_rate: resData.own_cost_info.payin_rate,
                  payin_extra_charge: resData.own_cost_info.payin_extra_charges,
                  payout_rate: resData.own_cost_info.payout_rate,
                  payout_extra_charge:resData.own_cost_info.payout_extra_charges,
                  settlement_rate: resData.own_cost_info.settlement_rate,
                  settlement_extra_charge: resData.own_cost_info.settlement_extra_charges,
                  ewallet_rate: resData.own_cost_info.ewallet_rate,
                  ewallet_extra_charge: resData.own_cost_info.ewallet_extra_charges
                }

                this.autoNextPg = resData.jump_each_transaction_by_sequence;

                this.formEditData2={
                  id:"",
                  currency: this.selectedPaymentGatewayCurrency.value,
                  min_deposit: resData.own_cost_info.minumum_deposit,
                  max_deposit: resData.own_cost_info.maximum_deposit,
                  min_payout: resData.own_cost_info.minumum_payout,
                  max_payout: resData.own_cost_info.maximum_payout,
                  min_settlement: resData.own_cost_info.minumum_settlement,
                  max_settlement: resData.own_cost_info.maximum_settlement,
                }
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.loading = false;
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      updateCompanyCost(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))
        if (this.$v.formEditData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("currency", this.formEditData.currency);
          bodyFormData.append("minumumDeposit", this.formEditData.min_deposit);
          bodyFormData.append("maximumDeposit", this.formEditData.max_deposit);
          bodyFormData.append("payinRate", this.formEditData.payin_rate);
          bodyFormData.append("payinExtraCharges", this.formEditData.payin_extra_charge);
          bodyFormData.append("payoutRate", this.formEditData.payout_rate);
          bodyFormData.append("payoutExtraCharges", this.formEditData.payout_extra_charge);
          bodyFormData.append("settlementRate", this.formEditData.settlement_rate);
          bodyFormData.append("settlementExtraCharges", this.formEditData.settlement_extra_charge);
          bodyFormData.append("ewalletRate", this.formEditData.ewallet_rate);
          bodyFormData.append("ewalletExtraCharges", this.formEditData.ewallet_extra_charge);
    
          axios({
              method: "post",
              url:  appConfig.APIHostAdmin + 'controller/admin/updateCompanyPgRateInfo',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'RRP Setting Updated',
                  html: 'RRP Setting has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  console.log("success")
                  this.getData()
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
        });
      }
      },
      updateCompanySetting(){
        this.modalSubmit4 = true
        this.$v.formEditData2.$touch();
        console.log((this.$v.formEditData2.$invalid))
        if (this.$v.formEditData2.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading4 = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("currency", this.formEditData2.currency);
          bodyFormData.append("minumumDeposit", this.formEditData2.min_deposit);
          bodyFormData.append("maximumDeposit", this.formEditData2.max_deposit);
          bodyFormData.append("minumumPayout", this.formEditData2.min_payout);
          bodyFormData.append("maximumPayout", this.formEditData2.max_payout);
          bodyFormData.append("minumumSettlement", this.formEditData2.min_settlement);
          bodyFormData.append("maximumSettlement", this.formEditData2.max_settlement);
    
          axios({
              method: "post",
              url:  appConfig.APIHostAdmin + 'controller/admin/updateCurrencyBasicSetting',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'General Setting Updated',
                  html: 'General Setting has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  console.log("success")
                  this.getData()
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading4 = false;
              this.modalSubmit4 = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading4 = false;
                this.modalSubmit4 = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
        });
      }
      },
      switchVisibility() {
        (this.classicon =
          this.classicon === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password");
      },
      switchVisibility2() {
        (this.classicon2 =
          this.classicon2 === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType2 =
            this.passwordFieldType2 === "password" ? "text" : "password");
      },
      
      updateStatus(value){
        this.$Progress.start();
        this.submitted = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("pgId", value.payment_db_code);
        bodyFormData.append("status", value.status);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/activeInactivePg',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            this.returnData = []
            if (resData.status == 200) {
              console.log(resData)
              this.getData();
            } 
            else if (resData.status == 440){
              // Swal.fire(
              //     {
              //     icon: 'error',
              //     title: 'Oops...',
              //     html: `${resData.message}.`,
              //     confirmButtonColor: '#222',
              //     confirmButtonText: this.$t('siteLang.Done'),
              //   })
              //   localStorage.clear();
              //   this.$router.push({
              //       path: "/login",
              // });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.submitted = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.submitted = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      updateSequence(){
        this.$Progress.start();
        this.modalLoading2 = true
        var bodyFormData = new FormData();
        var pgSequence = []
        this.returnData.forEach(element => {
          pgSequence.push(
            {
              'pgCode': element.payment_db_code,
              'sequence': element.sequence,
            })
        })
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("pgList", JSON.stringify(pgSequence));
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updatePgSequence',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            this.returnData = []
            if (resData.status == 200) {
              console.log(resData)
              this.getData();
            } 
            else if (resData.status == 440){
              // Swal.fire(
              //     {
              //     icon: 'error',
              //     title: 'Oops...',
              //     html: `${resData.message}.`,
              //     confirmButtonColor: '#222',
              //     confirmButtonText: this.$t('siteLang.Done'),
              //   })
              //   localStorage.clear();
              //   this.$router.push({
              //       path: "/login",
              // });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading2 = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading2 = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      
      removePaymentGateway(value) {
        Swal.fire(
          {
          icon: 'info',
          title: `Remove ${value.payment_gateway_name}?`,
          html: `Are you certain you want to remove ${value.payment_gateway_name} (${value.payment_gateway_code}). `,
          confirmButtonColor: '#222',
          confirmButtonText: "Yes, Remove",
          showCancelButton: true,
          cancelButtonColor: '#aaa',
          reverseButtons: true
        }).then((response) => {
            if (response.isConfirmed){
                this.$Progress.start();
                this.submitted = true
                var bodyFormData = new FormData();
                bodyFormData.append("accessToken", this.accessToken);
                bodyFormData.append("username",  this.accessUsername);
                bodyFormData.append("id", value);
                axios({
                    method: "post",
                    url: appConfig.DemoAPI,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response)=>{
                    this.returnData = []
                    var resData = response.data;
                    if (resData.status == 200) {
                      console.log(resData)
                      this.getData();
                    } 
                    else if (resData.status == 440){
                      Swal.fire(
                          {
                          icon: 'error',
                          title: 'Oops...',
                          html: `${resData.message}.`,
                          confirmButtonColor: '#222',
                          confirmButtonText: this.$t('siteLang.Done'),
                        })
                        localStorage.clear();
                        this.$router.push({
                            path: "/login",
                      });
                    }
                    else {
                        Swal.fire(
                          {
                          icon: 'error',
                          title: 'Oops...',
                          html: `${resData.message}.`,
                          confirmButtonColor: '#222',
                          confirmButtonText: this.$t('siteLang.Done'),
                        })
                    }
                    this.submitted = false;
                    this.$Progress.finish();
                  })
                  .catch((error)=> {
                      this.submitted = false;
                      this.$Progress.finish();
                      //Swal.fire("Error", error, "error");
                      Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${error}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                });
            }
        })
      },
      addMerchantRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("username",  this.accessUsername);
          // bodyFormData.append("upiID", this.addUPI.upi_id);
          // bodyFormData.append("upiName", this.addUPI.upi_name);
          // bodyFormData.append("type", this.addUPI.upi_type);
          // bodyFormData.append("transactionLimit", this.addUPI.transaction_limit.replaceAll(",",""));
  
          axios({
              method: "post",
              url: appConfig.DemoAPI,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'New UPI Account Added',
                  html: `UPI Account has been successfully added!`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-addmerchant'].hide()
                  this.getData();
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      clear(){
        this.searchKey=""
        this.searchKey2=""
      },
      filterByResult() {
        return this.returnData.filter((e) => {
            return (
            e.payment_gateway_name.toLowerCase().includes(this.searchKey.toLowerCase())
            );
        })
      },
      
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
  
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            const permission = data.modules.filter(e => e.name==='administrator')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "administrator" not found.');
            }
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
  
    }
  };
</script>
<style scoped>
  .bg-inactive{
    background-color: #ecd4e5;
  }
  .multiselect{min-height: auto;}
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
</style>