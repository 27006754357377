<template>
  <div>
    <b-modal @hidden="resetModal" ref="modal-pg-new" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-credit-card me-1 text-primary"></i>  Add Payment Gateway ({{ updateData.currency }})
           </h5>
        </template>
          <div class="row align-items-center">
              <div class="mb-3 col col-lg">
                <label class="mb-1 me-2">Select Payment Gateway ({{ updateData.currency }})</label>
                <multiselect @input="changeSubPg()" :allow-empty="false" deselect-label="At least one PG must be selected." v-model="formData.pg_data" :options="updateData.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Payment Gateway" label="" track-by="" :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.pg_data.$error,
                  }">
                 <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.pg_name!==''">
                      <span class="option__title">{{ props.option.pg_name }} </span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.pg_name!==''" class="option__title">{{ props.option.pg_name }}</span>
                  </template>
                            
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
              </div>
              
              <div class="mb-3 col-auto col-lg-auto">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Currency</label>
                  <div>
                    <input
                    :value="formData.currency"
                    disabled="true"
                    type="text"
                    class="form-control"
                    placeholder="Payment Gateway Code"
                    name="Payment Gateway Code"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.currency.$error,
                    }"
                    />
                    <div
                    v-if="modalSubmit && $v.formData.currency.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.currency.required"
                       >Currency is required.</span
                       >
                 </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="col-12 col-lg-6 mb-3">
                <div class="align-items-center">
                  <label>Payment Gateway Name</label>
                 <input
                    v-model="formData.pg_name"
                    type="text"
                    class="form-control"
                    @input="inputValue"
                    placeholder="Payment Gateway Name"
                    name="Payment Gateway Name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.pg_name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.pg_name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.pg_name.required"
                       >Payment Gateway Name is required.</span
                       >
                 </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <div class="align-items-center">
                  <label>Payment Gateway Code</label>
                 <input
                    :value="formData.pg_code"
                    disabled="true"
                    type="text"
                    class="form-control"
                    placeholder="Payment Gateway Code"
                    name="Payment Gateway Code"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.pg_code.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.pg_code.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.pg_name.required"
                       >Payment Gateway Code is required.</span
                       >
                 </div>
                </div>
              </div>
              <div class="col-12 mb-0">
                <div class="align-items-center">
                  <label>Extra Info
                    <span class="d-block font-size-13 fw-normal text-muted">**Please put the data in {PLEASE_CHECK_WITH_PG}</span>
                   
                    <span class="d-none font-size-12 fw-normal text-muted">Random ID: {{ generateRandomID() || '-'  }}</span>
                    <span class="d-none font-size-12 fw-normal text-muted">Random Key: {{ generateMD5Hash(formData.pg_code) || '-'  }}</span>
                  </label>
                 <textarea
                    v-model="formData.extra_info"
                    type="text"
                    id="jsonEditor"
                    class="form-control light"
                    placeholder="Extra Info"
                    rows="18"
                    autofocus
                    name="Extra Info"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.extra_info.$error,
                    }"
                    ></textarea>
                 <div
                    v-if="modalSubmit && $v.formData.extra_info.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.pg_name.required"
                       >Extra info is required.</span
                       >
                 </div>
                </div>
              </div>
              
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-pg-new'].hide()">Cancel</button>
                    <button type="button" class="btn fw-medium btn-info" @click="addNewPg()" :disabled="modalLoading || loading">
                    <span v-if="modalLoading"> Adding...</span>
                    <span v-else> Add Payment Gateway </span>
                    <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
              </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-pg-edit" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-credit-card me-1 text-primary"></i>  Payment Gateway Setting {{ '- '+ updateData.payment_gateway_name || '' }}
           </h5>
        </template>
          <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg">
                 <label class="mb-1">Payment Gateway</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ updateData.payment_gateway_name || '-' }}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Payment Gateway Code</label>
                  <div><span class="badge bg-soft-primary font-size-14">{{ updateData.payment_gateway_code || '-' }}</span></div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Country</label>
                  <div><span class="badge bg-soft-primary font-size-14">{{ `${updateData.country} (${updateData.currency})` ||  '-' }}</span></div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-auto">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Status</label>
                  <div>
                    <!-- <div class="form-check form-switch  form-check-custom ms-1 mb-0 font-size-15">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="1" false-value="0" :disabled="loading">
                    </div> -->
                    <select class="form-select pe-3" v-model="formEditData.status" :disabled="loading" style="min-width: 95px;">
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                          <option value="-1">Disabled</option>
                        </select>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-12">
                <label class="mb-1 me-2">Category</label>
                <multiselect v-model="formEditData.category" :options="pgCategory" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Category" label="" track-by="" class="text-capitalize" :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.category.$error,
                  }">
                 <template slot="option" slot-scope="props"><span class="option__title text-capitalize cursor-pointer">{{ props.option }}</span></template>
                 <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 mb-0 bg-soft-warning text-orange text-capitalize cursor-pointer">{{ option }} <span class="custom__remove" @click="remove(option)"><i class="uil uil-minus-circle"></i></span></span>
                 </span>
                  </template>
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                
                 <div v-if="modalSubmit && $v.formEditData.category.$error" class="invalid-feedback">
                  <span v-if="!$v.formEditData.category.required">Category is required.</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-12">
                <label class="mb-1 me-2">Support Type</label>
                <multiselect v-model="formEditData.support_type" :options="
                supportType"
                  :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Type" label="" track-by="" class="text-capitalize pb-1" :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.support_type.$error,
                  }">
                 <template slot="option" slot-scope="props"><span class="option__title text-capitalize cursor-pointer">{{ props.option.replaceAll('_',' ') }}</span></template>
                 <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 mb-md-0 mb-1 bg-soft-success text-success text-capitalize cursor-pointer">{{ option.replaceAll('_',' ') }} <span class="custom__remove" @click="remove(option)"><i class="uil uil-check-circle"></i></span></span>
                 </span>
                  </template>
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                
                 <div v-if="modalSubmit && $v.formEditData.support_type.$error" class="invalid-feedback">
                  <span v-if="!$v.formEditData.support_type.required">Support type is required.</span>
                </div>
              </div>
              <!-- <div class="mb-3 col-12 col-lg-12">
                <label class="mb-1 me-2">Support Type</label>
                <div v-if="updateData.supported_type" class="text-capitalize"><span  class="badge bg-soft-primary font-size-14 me-2" v-for="(value, index) in updateData.supported_type.split(',')" :key="index">{{ value }}</span></div>
              </div> -->
              <hr>
              <div class="col-12 mb-3">
                    <div class="progress progress-stacked mb-2" style="height: 36px; font-size: 16px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary font-size-14" v-if="updateData.wallet_info" :style="`width: ${parseFloat(updateData.wallet_info.deposit_balance/updateData.balance_limit || 0 )*100}%;`"> {{  parseFloat(parseFloat(updateData.wallet_info.deposit_balance/(updateData.balance_limit|| 0)) *100).toFixed(2)}}%</div>
                    </div>
                    <div class="d-lg-flex w-100">
                      <span v-if="updateData.wallet_info">Total Deposit: {{ convertCurrencyFormat(updateData.wallet_info.deposit_balance,true) }} (System)</span>
                      <span v-if="updateData.balance_limit" class="ms-lg-auto d-block d-lg-inline">Balance Limit: {{ convertCurrencyFormat(updateData.balance_limit,false) }}</span>
                    </div>
              </div>
              <div class="col-12 mb-3">
                    <div class="progress progress-stacked mb-2" style="height: 36px; font-size: 16px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary font-size-14" v-if="updateData.wallet_info" :style="`width: ${parseFloat(updateData.wallet_info.pg_deposit_balance/updateData.balance_limit || 0)*100}%;`"> {{  parseFloat(parseFloat(updateData.wallet_info.pg_deposit_balance/updateData.balance_limit || 0) *100).toFixed(2)}}%</div>
                    </div>
                    <div class="d-lg-flex w-100">
                      <span v-if="updateData.wallet_info">Total Deposit: {{ convertCurrencyFormat(updateData.wallet_info.pg_deposit_balance,true) }} (PG)</span>
                      <span v-if="updateData.balance_limit" class="ms-lg-auto d-block d-lg-inline">Balance Limit: {{ convertCurrencyFormat(updateData.balance_limit,false) }}</span>
                    </div>
              </div>
              <div class="mb-3 col-12 col-lg-4">
                 <label class="mb-1">Min. Deposit</label>
                 <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.min_deposit.$error}" 
                  v-model="formEditData.min_deposit" placeholder="Min. Deposit" @input="inputNumberOnly($event, 'formEditData','min_deposit')" :disabled="loading">
                <div
                  v-if="modalSubmit && $v.formEditData.min_deposit.$error"
                  class="invalid-feedback"
                  >
                  <span v-if="!$v.formEditData.min_deposit.required"
                    >Min. Deposit is required. </span
                    >
                </div>
              </div>
              <div class="mb-4 col-12 col-lg-4">
                <label class="mb-1">Max. Deposit</label>
                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.max_deposit.$error}" 
                    v-model="formEditData.max_deposit" placeholder="Max. Deposit" @input="inputNumberOnly($event, 'formEditData','max_deposit')" :disabled="loading">
                  <div
                    v-if="modalSubmit && $v.formEditData.max_deposit.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.max_deposit.required"
                      >Max. Deposit is required. </span
                      >
                  </div>
              </div>

              <div class="mb-4 col-12 col-lg-4">
                <label class="mb-1">Balance Limit</label>
                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.balance_limit.$error}" 
                    v-model="formEditData.balance_limit" placeholder="Balance Limit" @input="inputNumberOnly($event, 'formEditData','balance_limit')" :disabled="loading">
                  <div
                    v-if="modalSubmit && $v.formEditData.balance_limit.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.balance_limit.required"
                      >Balance limit is required. </span
                      >
                  </div>
              </div>
          
              <hr>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Payin Rate and Extra Charges</label>
                 <div class="row">
                    <div class="col-6">
                      <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_rate.$error}" 
                        v-model="formEditData.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payin_rate')" >
                      <div
                        v-if="modalSubmit && $v.formEditData.payin_rate.$error"
                        class="invalid-feedback"
                        >
                        <span v-if="!$v.formEditData.payin_rate.required"
                          >The payin rate is required.</span
                          >
                        <span v-else-if="!$v.formEditData.payin_rate.between"
                          >The payin rate should be above 0.10% but below 50%.</span
                          > 
                      </div>
                    </div>
                    <div class="col-6">
                      <input type="text" inputmode="decimal" class="form-control text-start" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_extra_charge.$error}" 
                        v-model="formEditData.payin_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payin_extra_charge')" >
                      <div
                        v-if="modalSubmit && $v.formEditData.payin_extra_charge.$error"
                        class="invalid-feedback"
                        >
                        <span v-if="!$v.formEditData.payin_extra_charge.required"
                          >The extra charge is required. </span
                          >
                        <span v-else-if="!$v.formEditData.payin_extra_charge.between"
                          >The extra charge can be 0.00 or any value above 0.00.</span
                          > 
                      </div>
                    </div>
                  </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">Payout Rate and Extra Charges</label>
                <div class="row">
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_rate.$error}" 
                      v-model="formEditData.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payout_rate')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.payout_rate.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.payout_rate.required"
                        >The payout rate is required.</span
                        >
                      <span v-else-if="!$v.formEditData.payout_rate.between"
                        >The payout rate should be above 0.10% but below 50%.</span
                        > 
                    </div>
                  </div>
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_extra_charge.$error}" 
                      v-model="formEditData.payout_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payout_extra_charge')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.payout_extra_charge.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.payout_extra_charge.required"
                        >The extra charge is required. </span
                        >
                      <span v-else-if="!$v.formEditData.payout_extra_charge.between"
                        >The extra charge can be 0.00 or any value above 0.00.</span
                        > 
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">Settlement Rate and Extra Charges</label>
                <div class="row">
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_rate.$error}" 
                      v-model="formEditData.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','settlement_rate')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.settlement_rate.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.settlement_rate.required"
                        >The settlement rate is required.</span
                        >
                      <span v-else-if="!$v.formEditData.settlement_rate.between"
                        >The settlement rate should be above 0.10% but below 50%.</span
                        > 
                    </div>
                  </div>
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_extra_charge.$error}" 
                      v-model="formEditData.settlement_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','settlement_extra_charge')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.settlement_extra_charge.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.settlement_extra_charge.required"
                        >The extra charge is required. </span
                        >
                      <span v-else-if="!$v.formEditData.settlement_extra_charge.between"
                        >The extra charge can be 0.00 or any value above 0.00.</span
                        > 
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">E-Wallet Rate and  Extra Charges</label>
                <div class="row">
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_rate.$error}" 
                      v-model="formEditData.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_rate')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.ewallet_rate.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.ewallet_rate.required"
                        >The e-wallet rate is required.</span
                        >
                      <span v-else-if="!$v.formEditData.ewallet_rate.between"
                        >The e-wallet rate should be above 0.10% but below 50%.</span
                        > 
                    </div>
                  </div>
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_extra_charge.$error}" 
                      v-model="formEditData.ewallet_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_extra_charge')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.ewallet_extra_charge.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.ewallet_extra_charge.required"
                        >The extra charge is required. </span
                        >
                      <span v-else-if="!$v.formEditData.ewallet_extra_charge.between"
                        >The extra charge can be 0.00 or any value above 0.00.</span
                        > 
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" class="form-control" v-model="formEditData.currency" hidden>
              
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-pg-edit'].hide()">Cancel</button>
                    <button type="button" class="btn fw-medium btn-info" @click="updatePgSetting" :disabled="modalLoading || loading">
                    <span v-if="modalLoading"> Updating...</span>
                    <span v-else> Update </span>
                    <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
              </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-pg-edit-balance" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
             <i class="uil uil-credit-card me-1 text-primary"></i> Update PG Balance
           </h5>
        </template>
        <b-card no-body class="mb-0">
          <b-tabs card  @activate-tab="tabsChanging">
            <b-tab title="Payment Gateway Info">
              <b-card-text>
                <div class="row align-items-top">
                  <div class="mb-3 col-12 col-lg-4">
                      <label class="mb-1">Payment Gateway</label>
                      <div><span class="badge bg-soft-primary font-size-14">{{ updateData.payment_gateway_name || '-' }}</span></div>
                  </div>
                  
                  <div class="mb-3 col-12 col-lg-4">
                      <label class="mb-1">Payment Gateway Code</label>
                      <div><span class="badge bg-soft-primary font-size-14">{{ updateData.payment_gateway_code || '-' }}</span></div>
                  </div>

                  <div class="mb-3 col-12 col-lg-4">
                    <label class="mb-1">Country</label>
                    <div><span class="badge bg-soft-primary font-size-14">{{ `${updateData.country} (${updateData.currency})` ||  '-' }}</span></div>
                    
                  </div>
                
                  <div class="mb-1 col-12 col-lg-auto">
                      <label class="d-block mb-1">Adjust By</label>
                      <div>
                        <input type="radio" v-model="formEditBalanceData.type" value="+" class="btn-check" name="adjust_type" id="adding" autocomplete="off" :disabled="modalLoading || loading" >
                        <label class="btn btn-outline-info me-1 font-size-16 p-2 lh-1" :class="{'btn-info':formEditBalanceData.type=='+'}" for="adding"><i class="uil uil-plus"></i></label>

                        <input type="radio" v-model="formEditBalanceData.type" value="-"  class="btn-check" name="adjust_type" id="subtracting" autocomplete="off" :disabled="modalLoading || loading">
                        <label class="btn btn-outline-info me-1 font-size-16 p-2 lh-1" :class="{'btn-info':formEditBalanceData.type=='-'}" for="subtracting"><i class="uil uil-minus"></i></label>
                      </div>
                  </div>

                  <div class="mb-3 col-12 col-lg">
                      <label class="mb-1">Amount</label>
                      <input
                        v-model="formEditBalanceData.amount"
                        autocomplete="off"
                        type="text"
                        inputmode="decimal"
                        @input="inputNumberOnlyV2($event, 'formEditBalanceData','amount'), detectOperator($event)" 
                        class="form-control"
                        placeholder="Amount"
                        :disabled="modalLoading || loading"
                        name="amount"
                        :class="{
                        'is-invalid':
                        modalSubmit && $v.formEditBalanceData.amount.$error,
                        }"
                        />
                      <div
                        v-if="modalSubmit && $v.formEditBalanceData.amount.$error"
                        class="invalid-feedback"
                        >
                        <span v-if="!$v.updateBalance.amount.required"
                            >Amount is required.</span
                            >
                      </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="p-3 bg-light rounded">
                      <div class="row">
                      <div class="mb-2 mb-lg-0 col-12 col-lg-4">
                      <label class="mb-1 fw-normal">Current Balance  </label>
                      <div>
                      <span class="fw-semibold font-size-20" v-if="formEditBalanceData.pg_deposit_balance"> 
                        {{ convertCurrencyFormat(formEditBalanceData.pg_deposit_balance,true)}}
                      </span>
                      <span v-else class="fw-semibold font-size-20">0.00</span>
                      </div>
                      </div>

                      <div class="mb-2 mb-lg-0 col-12 col-lg-4">
                          <label class="mb-0 fw-normal">Amount</label>
                          <div>
                          <span class="fw-semibold font-size-20" v-if="formEditBalanceData.amount">
                            <span v-if="formEditBalanceData.type=='+'" class="text-success">
                              {{ formEditBalanceData.type }} {{ convertCurrencyFormat(formEditBalanceData.amount,true)}}
                            </span> 
                            <span v-if="formEditBalanceData.type=='-'" class="text-danger">
                              {{ formEditBalanceData.type }} {{ convertCurrencyFormat(formEditBalanceData.amount,true)}}
                            </span> 
                          
                          </span>
                          <span v-else class="fw-semibold font-size-20">
                            {{formEditBalanceData.type}} 0.00</span>
                          </div>
                      </div>
                      <div class="mb-0 col-12 col-lg-4">
                          <label class="mb-0 fw-normal">Est. After Balance  </label>
                          <div>
                          <span class="fw-semibold font-size-20" v-if="formEditBalanceData.pg_deposit_balance && formEditBalanceData.amount"> 
                            <span v-if="formEditBalanceData.type=='+'">
                            {{ convertCurrencyFormat(formEditBalanceData.pg_deposit_balance + parseFloat(formEditBalanceData.amount),true) }}
                            </span>
                            <span v-if="formEditBalanceData.type=='-'">
                            {{ convertCurrencyFormat(formEditBalanceData.pg_deposit_balance - parseFloat(formEditBalanceData.amount),true) }}
                            </span>
                          </span>
                          <span v-else class="fw-semibold font-size-20">0.00</span>
                          </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="mb-3 col-12 col-md-12">
                    <label class="mb-1">Password</label>
                    <div class="position-relative">
                      <input
                          v-model="formEditBalanceData.password"
                          :type="passwordFieldType"
                          name="password"
                          class="form-control"
                          :class="{
                          'is-invalid':
                          modalSubmit && $v.formEditBalanceData.password.$error,
                          }"
                          placeholder="Password"
                          />
                      <div
                          v-if="modalSubmit && $v.formEditBalanceData.password.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formEditBalanceData.password.required"
                            >Password is required.</span
                            >
                      </div>
                      <div
                          id="eye"
                          class="user-select-none"
                          @click="switchVisibility()"
                          >
                          <i
                            :class="classicon"
                            id="eyetype"
                            aria-hidden="true"
                            ></i>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-12 col-lg-12">
                    <label class="mb-1">Remarks <small class="text-muted fw-normal">(Optional)</small></label>
                    <div class="position-relative">
                      <input
                        type="Remarks"
                        v-model="formEditBalanceData.remarks"
                        class="form-control"
                        placeholder="Remarks"
                        />
                    </div>
                  </div>
                  <div class="col-12">
                      <hr>
                      <div class="d-grid gap-2 d-md-block text-end">
                        <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-pg-edit-balance'].hide()">Cancel</button>
                        
                        <div class="d-inline" v-if="formEditBalanceData.type=='-'">
                          <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(formEditBalanceData.pg_deposit_balance) - parseFloat(formEditBalanceData.amount))>=0" @click="updatePgBalance" :disabled="modalLoading || loading">
                          <span v-if="modalLoading"> Updating...</span>
                          <span v-else> Update </span>
                          <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                        
                          </button>
                          <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                        </div>  
                        <div class="d-inline" v-if="formEditBalanceData.type=='+'">
                          <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(formEditBalanceData.pg_deposit_balance) + parseFloat(formEditBalanceData.amount))>=0" @click="updatePgBalance" :disabled="modalLoading || loading">
                          <span v-if="modalLoading"> Updating...</span>
                          <span v-else> Update </span>
                          <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                        
                          </button>
                          <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                        </div> 
                      </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Updates History" >
              <b-card-text>
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive text-nowrap font-size-14 position-relative">
                      <table class="table mt-0 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                        <thead class="text-uppercase">
                          <tr>
                            <th>ID</th>
                            <th class="text-end">Opening Balance</th>
                            <th class="text-end">Debit</th>
                            <th class="text-end">Credit</th>
                            <th class="text-end">Closing Balance</th>
                            <th>Updated Date</th>
                            <th>Remarks</th>                     
                            <th class="text-center">Updated By</th>    
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="!returnData.length && !loading">
                            <td :colspan="'8'" height="300" class="text-center text-muted">
                               No Data Available
                            </td>
                          </tr>
                          <tr v-if="loading">
                            <td :colspan="'8'" class="text-center text-muted" height="400">
                              <div
                                class="spinner-border text-secondary my-2"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                              <br />
                              <div>Loading...</div>
                            </td>
                          </tr>
                          
                          <tr v-for="(value, index) in returnData" :key="index" v-show="!loading">
                            <td>
                             {{value.id}}
                            </td>
                            <td class="text-end">
                             <span v-if="value.opening_balance"> {{convertCurrencyFormat(value.opening_balance,true)}}</span>
                            </td>
                            <td class="text-danger text-end">
                              <span v-if="value.debit_amount>0"> {{convertCurrencyFormat(value.debit_amount,true)}}</span>
                            </td>
                            <td class="text-success text-end">
                              <span v-if="value.credit_amount>0"> {{convertCurrencyFormat(value.credit_amount,true)}}</span>
                            </td>
                            <td class="text-end">
                              <span v-if="value.closing_balance"> {{convertCurrencyFormat(value.closing_balance,true)}}</span>
                            </td>
                            <td>
                              {{value.update_datetime}}
                            </td>
                            <td class="text-muted">
                              {{value.remarks || '-'}}
                            </td>
                            <td class="text-center">
                              {{value.update_by}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </div>
                  <div class="row" v-if="returnData.length && !loading">
                    <div class="col mt-3">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-end"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            hide-ellipsis
                            @change="handlePageChange"
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalData"
                          >
                          </b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
     
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { 
    required, 
    between
    //url
  } from "vuelidate/lib/validators";

  /**
   * Starter page
   */

  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    watch: {
      handler(newVal) {
          console.log('dataProp has changed:', newVal);
        },
        deep: true, // Watch for deep changes within the object
    },
    data() {
      return {
        updateData: {},
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        currentPage: 1,
        perPage: 10,
        totalData: 0,
        returnData:[],
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        pgCategory:['gaming','forex','crypto','porn','scam'],
        supportType:['payin','ewallet','auto_payout','manual_payout','settlement'],
        formData: {
          pg_data:{},
          pg_name:"",
          pg_code: "",
          currency: "",
          extra_info:{}
        },
        formEditData: {
          id:"",
          status:"",
          category:[],
          support_type:[],
          min_deposit:"",
          max_deposit:"",
          balance_limit:"",
          currency:"",
          payin_rate: "",
          payin_extra_charge: "0",
          payout_rate: "",
          payout_extra_charge: "0",
          settlement_rate: "",
          settlement_extra_charge: "0",
          ewallet_rate: "",
          ewallet_extra_charge: "0",
        },
        formEditBalanceData: {
          id:"",
          type:"+",
          pg_deposit_balance:"",
          amount:"",
          remarks:"",
          password:""
        }
      }
    },
    validations: {
      formData: {
        pg_data: {
          required,
        },
        pg_code: {
          required,
        },
        pg_name: {
          required,
        },
        currency: {
          required,
        },
        extra_info: {
          required,
        }
      },
      formEditData: {
        currency: {
          required,
        },
        min_deposit: {
          required,
        },
        max_deposit: {
          required,
        },
        balance_limit: {
          required,
        },
        payin_rate: {
          required,
          between: between(0,50)
        },
        payin_extra_charge: {
          required,
          between: between(0,100000)
        },
        payout_rate: {
          required,
          between: between(0,50)
        },
        payout_extra_charge: {
          required,
          between: between(0,100000)
        },
        settlement_rate: {
          required,
          between: between(0,50)
        },
        settlement_extra_charge: {
          required,
          between: between(0,100000)
        },
        ewallet_rate: {
          required,
          between: between(0,50)
        },
        ewallet_extra_charge: {
          required,
          between: between(0,100000)
        },
        category: {
          required,
        },
        support_type: {
          required,
        },
      },
      formEditBalanceData:{
        id: {
          required,
        },
        pg_deposit_balance: {
          required,
        },
        amount: {
          required,
        },
        password: {
          required,
        },
      }
    },
    middleware: "authentication",
    async mounted() {
      this.reload();
      //   this.title = PageTitle
      //   this.items[1].text = PageTitle
      //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      //   // this.$refs.main.changeVerticalTopBar("bill",true)
      //   // this.$refs.main.setShowFooterCert(false)
      //   // this.$refs.main.setPageTitle('title')
      //   console.log( this.$refs.commonFunc.getPhone())
      //   this.accessToken = this.$refs.commonFunc.getToken()
      //   this.accessUsername = this.$refs.commonFunc.getUsername()
    },
    created() {
    },
    methods: {
      detectOperator(event) {
        if (event.target.value.includes('+')) {
          this.formEditBalanceData.type = '+'
        } else if (event.target.value.includes('-')) {
          this.formEditBalanceData.type = '-'
        } 
      },
      tabsChanging: function(e){
        console.log(e);
        if (e==0){
          console.log(e);
        }
        else if (e==1){
          //this.handlePageChange(1);
        }
      },
      switchVisibility() {
        (this.classicon =
          this.classicon === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password");
      },
      inputNumberOnly(event, parentModel, childModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        // if(numericValue.length > 2){
        //   var val = numericValue.replace(/[^0-9]/g, '');
        //   console.log(val);
        //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
        //   this[parentModel][childModel] = val;
        // }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        //this[parentModel][childModel] = numericValue
        this[parentModel].contract_selected[index][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel].contract_selected[index][childModel] = val;
        }
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-merchant'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData
      },
      inputValue(){
        setTimeout(() => { this.prettyPrint()}, 0)
      },
      showAddModal() {
        this.$refs['modal-pg-new'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.formData= {
          pg_data:this.data.updateData.data[0],
          pg_name:"",
          pg_code: this.data.updateData.data[0].new_pg_code,
          currency: this.data.updateData.data[0].currency,
          extra_info: JSON.stringify(this.data.updateData.data[0].extra_info)
        }
        setTimeout(() => { this.prettyPrint()}, 0)
      },
      changeSubPg(){
        this.formData.pg_code = '';
        this.formData.currency = '';
        this.formData.extra_info = '';
        this.formData.pg_code = this.formData.pg_data.new_pg_code || '';
        this.formData.currency = this.formData.pg_data.currency || '';
        this.formData.extra_info = JSON.stringify(this.formData.pg_data.extra_info) || '';
        setTimeout(() => { this.prettyPrint()},0)
       
      },
      prettyPrint() {
          var ugly = document.getElementById('jsonEditor').value;
          var obj = JSON.parse(ugly);
          var pretty = JSON.stringify(obj, undefined, 2);
          document.getElementById('jsonEditor').value = pretty;
      },
      generateRandomID() {
        let id = '';
        while (id.length < 16) {
            id += Math.floor(Math.random() * 10).toString();
        }
        return id;
      },
      showEditModal() {
        this.$refs['modal-pg-edit'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData

        this.formEditData= {
            id: this.data.updateData.payment_db_code,
            status: this.data.updateData.status,
            category: this.data.updateData.categories,
            support_type: this.data.updateData.supported_type.split(','),
            min_deposit: this.data.updateData.min_deposit,
            max_deposit:this.data.updateData.max_deposit,
            balance_limit:this.data.updateData.balance_limit,
            currency:this.data.updateData.currency,
            payin_rate: this.data.updateData.payin_rate,
            payin_extra_charge: this.data.updateData.payin_extra_charge,
            payout_rate: this.data.updateData.payout_rate,
            payout_extra_charge: this.data.updateData.payout_extra_charge,
            settlement_rate:this.data.updateData.settlement_rate,
            settlement_extra_charge: this.data.updateData.settlement_extra_charge,
            ewallet_rate: this.data.updateData.ewallet_rate,
            ewallet_extra_charge: this.data.updateData.ewallet_extra_charge,
        }
        //this.getReseller('edit');
      },
      showEditPgBalanceModal() {
        this.$refs['modal-pg-edit-balance'].show();
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData
        this.formEditBalanceData = {
          id: this.data.updateData.payment_gateway_code,
          type: "+",
          pg_deposit_balance: this.data.updateData.wallet_info.pg_deposit_balance,
          amount: "",
          remarks:"",
          password:""
        }

        this.handlePageChange(1);
        this.tabsChanging(0)
        this.formEditData= {
            id: this.data.updateData.payment_db_code,
            status: this.data.updateData.status,
            category: this.data.updateData.categories,
            support_type: this.data.updateData.supported_type.split(','),
            min_deposit: this.data.updateData.min_deposit,
            max_deposit:this.data.updateData.max_deposit,
            balance_limit:this.data.updateData.balance_limit,
            currency:this.data.updateData.currency,
            payin_rate: this.data.updateData.payin_rate,
            payin_extra_charge: this.data.updateData.payin_extra_charge,
            payout_rate: this.data.updateData.payout_rate,
            payout_extra_charge: this.data.updateData.payout_extra_charge,
            settlement_rate:this.data.updateData.settlement_rate,
            settlement_extra_charge: this.data.updateData.settlement_extra_charge,
            ewallet_rate: this.data.updateData.ewallet_rate,
            ewallet_extra_charge: this.data.updateData.ewallet_extra_charge,
        }
        //this.getReseller('edit');
      },
      resetModal() {
        this.modalSubmit = false;
        this.modalLoading = false;
        this.currentPage = 1;
        this.formEditBalanceData = {
          id:"",
          type:"+",
          pg_deposit_balance: "",
          remarks:"",
          password:""
        }
       this.formData= {
          pg_data:{},
          pg_name:"",
          pg_code: "",
          currency: "",
          extra_info:{}
        }
        this.formEditData = {
          id:"",
          status:"",
          category:[],
          min_deposit:"",
          max_deposit:"",
          currency:"",
          payin_rate: "",
          payin_extra_charge: "0",
          payout_rate: "",
          payout_extra_charge: "0",
          settlement_rate: "",
          settlement_extra_charge: "0",
          ewallet_rate: "",
          ewallet_extra_charge: "0",
        }
      },
      updatePgSetting(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        if (this.$v.formEditData.$invalid) {
          return;
        }
        // else if (this.formEditData.support_type.includes('payout') && this.formEditData.support_type.includes('auto_payout')) {
        //   Swal.fire(
        //     {
        //     icon: 'error',
        //     title: 'Oops...',
        //     html: `The support type can only select one option: either payout or auto payout.`,
        //     confirmButtonColor: '#222',
        //     confirmButtonText: this.$t('siteLang.Done'),
        //   })
        //   return;
        // }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("pgCode", this.formEditData.id);
          bodyFormData.append("status", this.formEditData.status);
          bodyFormData.append("categories", JSON.stringify(this.formEditData.category));
          bodyFormData.append("supportType", this.formEditData.support_type.toString());
          bodyFormData.append("currency", this.formEditData.currency);
          bodyFormData.append("minumumDeposit", this.formEditData.min_deposit);
          bodyFormData.append("maximumDeposit", this.formEditData.max_deposit);
          bodyFormData.append("balanceLimit", this.formEditData.balance_limit);
          bodyFormData.append("payinRate", this.formEditData.payin_rate);
          bodyFormData.append("payinExtraCharges", this.formEditData.payin_extra_charge);
          bodyFormData.append("payoutRate", this.formEditData.payout_rate);
          bodyFormData.append("payoutExtraCharges", this.formEditData.payout_extra_charge);
          bodyFormData.append("settlementRate", this.formEditData.settlement_rate);
          bodyFormData.append("settlementExtraCharges", this.formEditData.settlement_extra_charge);
          bodyFormData.append("ewalletRate", this.formEditData.ewallet_rate);
          bodyFormData.append("ewalletExtraCharges", this.formEditData.ewallet_extra_charge);

          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/updatePaymentGatewaySetting',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: `${this.updateData.payment_gateway_name} Setting Updated`,
                  html: 'Payment gateway setting has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-pg-edit'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updatePgBalance(){
        this.modalSubmit = true
        this.$v.formEditBalanceData.$touch();
        if (this.$v.formEditBalanceData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("pgCode", this.formEditBalanceData.id);
          bodyFormData.append("amount", this.formEditBalanceData.amount);
          bodyFormData.append("type", this.formEditBalanceData.type);
          bodyFormData.append("remarks", this.formEditBalanceData.remarks);
          bodyFormData.append("password", this.formEditBalanceData.password);

          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/updatePgBalance',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: `${this.updateData.payment_gateway_name} Balance Updated`,
                  html: 'Payment gateway balance has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-pg-edit-balance'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      addNewPg(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        setTimeout(() => { this.prettyPrint()}, 100)
        if (this.$v.formData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("pgName", this.formData.pg_name);
          bodyFormData.append("pgCode", this.formData.pg_code);
          bodyFormData.append("currency", this.formData.currency);
          bodyFormData.append("extraInfo",JSON.stringify(JSON.parse(this.formData.extra_info)));
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/addOneToOnePg',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: `${this.formData.pg_name}  Added`,
                  html: 'New payment gateway has been successfully added!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-pg-new'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      handlePageChange(current_page) {
        this.currentPage = current_page;
        this.getUpdatePgBalanceHistory(current_page, this.search_value);
        //this.exportData(1)
      },
      getUpdatePgBalanceHistory(pages){
        this.$Progress.start();
        this.loading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("pgCode", this.formEditBalanceData.id);

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getPgBalanceHistory',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.returnData = resData.data
              this.totalData = resData.total
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      generateMD5Hash(value) {
        const uniqueParam = `${new Date().getTime()}`;
        const hash = this.md5(value+uniqueParam);
        return hash.toString();
      },
      // MD5 implementation in JavaScript
      md5(string) {
            function rotateLeft(lValue, iShiftBits) {
                return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
            }

            function addUnsigned(lX, lY) {
                let lX4, lY4, lX8, lY8, lResult;
                lX8 = (lX & 0x80000000);
                lY8 = (lY & 0x80000000);
                lX4 = (lX & 0x40000000);
                lY4 = (lY & 0x40000000);
                lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
                if (lX4 & lY4) {
                    return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
                }
                if (lX4 | lY4) {
                    if (lResult & 0x40000000) {
                        return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
                    } else {
                        return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
                    }
                } else {
                    return (lResult ^ lX8 ^ lY8);
                }
            }

            function F(x, y, z) { return (x & y) | ((~x) & z); }
            function G(x, y, z) { return (x & z) | (y & (~z)); }
            function H(x, y, z) { return (x ^ y ^ z); }
            function I(x, y, z) { return (y ^ (x | (~z))); }

            function FF(a, b, c, d, x, s, ac) {
                a = addUnsigned(a, addUnsigned(addUnsigned(F(b, c, d), x), ac));
                return addUnsigned(rotateLeft(a, s), b);
            }

            function GG(a, b, c, d, x, s, ac) {
                a = addUnsigned(a, addUnsigned(addUnsigned(G(b, c, d), x), ac));
                return addUnsigned(rotateLeft(a, s), b);
            }

            function HH(a, b, c, d, x, s, ac) {
                a = addUnsigned(a, addUnsigned(addUnsigned(H(b, c, d), x), ac));
                return addUnsigned(rotateLeft(a, s), b);
            }

            function II(a, b, c, d, x, s, ac) {
                a = addUnsigned(a, addUnsigned(addUnsigned(I(b, c, d), x), ac));
                return addUnsigned(rotateLeft(a, s), b);
            }

            function convertToWordArray(string) {
                let lWordCount;
                const lMessageLength = string.length;
                const lNumberOfWords_temp1 = lMessageLength + 8;
                const lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
                const lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
                const lWordArray = new Array(lNumberOfWords - 1);
                let lBytePosition = 0;
                let lByteCount = 0;

                while (lByteCount < lMessageLength) {
                    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
                    lBytePosition = (lByteCount % 4) * 8;
                    lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
                    lByteCount++;
                }

                lWordCount = (lByteCount - (lByteCount % 4)) / 4;
                lBytePosition = (lByteCount % 4) * 8;
                lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
                lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
                lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
                return lWordArray;
            }

            function wordToHex(lValue) {
                let wordToHexValue = "",
                    wordToHexValue_temp = "",
                    lByte, lCount;
                for (lCount = 0; lCount <= 3; lCount++) {
                    lByte = (lValue >>> (lCount * 8)) & 255;
                    wordToHexValue_temp = "0" + lByte.toString(16);
                    wordToHexValue = wordToHexValue + wordToHexValue_temp.substr(wordToHexValue_temp.length - 2, 2);
                }
                return wordToHexValue;
            }

            function utf8Encode(string) {
                string = string.replace(/\r\n/g, "\n");
                let utftext = "";

                for (let n = 0; n < string.length; n++) {
                    const c = string.charCodeAt(n);

                    if (c < 128) {
                        utftext += String.fromCharCode(c);
                    } else if ((c > 127) && (c < 2048)) {
                        utftext += String.fromCharCode((c >> 6) | 192);
                        utftext += String.fromCharCode((c & 63) | 128);
                    } else {
                        utftext += String.fromCharCode((c >> 12) | 224);
                        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                        utftext += String.fromCharCode((c & 63) | 128);
                    }
                }

                return utftext;
            }

            let x = [],
                k, AA, BB, CC, DD, a, b, c, d,
                S11 = 7, S12 = 12, S13 = 17, S14 = 22,
                S21 = 5, S22 = 9, S23 = 14, S24 = 20,
                S31 = 4, S32 = 11, S33 = 16, S34 = 23,
                S41 = 6, S42 = 10, S43 = 15, S44 = 21;

            string = utf8Encode(string);

            x = convertToWordArray(string);

            a = 0x67452301;
            b = 0xEFCDAB89;
            c = 0x98BADCFE;
            d = 0x10325476;

            for (k = 0; k < x.length; k += 16) {
                AA = a;
                BB = b;
                CC = c;
                DD = d;
                a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
                d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
                c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
                b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
                a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
                d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
                c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
                b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
                a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
                d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
                c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
                b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
                a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
                d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
                c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
                b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
                a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
                d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
                c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
                b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
                a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
                d = GG(d, a, b, c, x[k + 10], S22, 0x02441453);
                c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
                b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
                a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
                d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
                c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
                b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
                a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
                d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
                c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
                b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
                a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
                d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
                c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
                b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
                a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
                d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
                c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
                b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
                a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
                d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
                c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
                b = HH(b, c, d, a, x[k + 6], S34, 0x04881D05);
                a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
                d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
                c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
                b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
                a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
                d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
                c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
                b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
                a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
                d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
                c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
                b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
                a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
                d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
                c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
                b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
                a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
                d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
                c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
                b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
                a = addUnsigned(a, AA);
                b = addUnsigned(b, BB);
                c = addUnsigned(c, CC);
                d = addUnsigned(d, DD);
            }

            const temp = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);

            return temp.toLowerCase();
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  }
</script>
<style scoped>
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
   /* a class name for the editor */
.light {
    background: #000;
    border-radius: 5px;
    border: 1px solid black;
    font-family: monospace;
    color: #ccc;
    font-size: 13px;
}
</style>